import 'react-range-slider-input/dist/style.css';
import '@/assets/chrome-bug.css';
import '@/assets/main.css';
import '@/assets/progress.css';
import '@/assets/ticker.css';
import '@/assets/landing.css';
import '@/assets/report.css';

import 'prismjs/themes/prism-funky.css';
import '@/assets/slider.css';

import Layout from '@/components/Layout';
import { MyUserContextProvider } from '@/utils/useUser';

import { supabase } from '@/utils/supabase-client';

import { useLoading } from '@/hooks';
import { NotificationProvider } from '@/hooks/useNotification';
import { SocketProvider } from '@/hooks/useSocket';
import PlausibleProvider from 'next-plausible';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { MshPage } from '@/types/frontend/page';
import React from 'react';
import { AppStateContextProvider } from '@/context/appContext';
import Script from 'next/script';
import getConfig from 'next/config';
import CookieNotice from '@/components/ui/Cookies/CookieNotice';
import usePageView from '@/hooks/usePageview';
import 'prismjs/themes/prism.css';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'react-tooltip/dist/react-tooltip.css';

import useApp from '@/hooks/useApp';
import { Tooltip } from 'react-tooltip';

declare global {
  interface Window {
    webkit: any;
  }
}

const { publicRuntimeConfig } = getConfig();
// const socket = io('ws://localhost:8080');
const socket = io('wss://quotes.markets.sh');
type Props<P = {}> = AppProps<P> & {
  Component: MshPage<P>;
  initialCookies: any;
};

function PageViewTracker() {
  usePageView();
  return null;
}
const euCountries = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HU',
  'HR',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'UK'
];

export function MyApp({ Component, pageProps, initialCookies }: Props<{}>) {
  const [renderClient, setRenderClient] = useState(false);

  const router = useRouter();

  const loading = useLoading();
  const loadingRef = useRef(loading);
  const [consentGiven, setConsentGiven] = useState<boolean>();
  const { redirectTo } = router.query;

  const isApp = useApp();

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) console.log('Error logging out:', error.message);
  }
  useEffect(() => {
    // Attach the signOut function to the window object
    (window as any).signOut = signOut;
  }, []);

  useEffect(() => {
    if (redirectTo) {
      // Perform the redirect
      router.push(redirectTo as string);

      // Remove the query parameter
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete('redirectTo');
      url.search = params.toString();
      const urlWithoutQueryParam = url.toString();

      router.replace(urlWithoutQueryParam, undefined, { shallow: true });
    }
  }, [redirectTo, router]);

  useEffect(() => {
    // if (!isEu()) {
    //   setConsentGiven(true);
    //   localStorage.setItem('tracking-opt-out', 'false');
    // } else {
    setConsentGiven(
      localStorage?.getItem('tracking-opt-out') === 'false' ? true : false
    );
    // }
    setRenderClient(true);
  }, []);

  const startLoadingIndicator = () => {
    loadingRef.current.start();
  };

  const routeChangeStartHandler = (params) => {
    startLoadingIndicator();

    if (
      isApp &&
      typeof window.webkit !== 'undefined' &&
      window.webkit?.messageHandlers?.routeChangeStartHandler?.postMessage
    ) {
      window?.webkit?.messageHandlers?.routeChangeStartHandler?.postMessage(
        params
      );
    }
    const urls = JSON.parse(localStorage?.getItem('app-native-links') || '[]');
    if (urls?.includes(params)) {
      loadingRef.current.done();
      throw new Error('not navigating to native url');
    }
  };
  const stopLoadingIndicator = () => {
    loadingRef.current.done();
  };
  useEffect(() => {
    stopLoadingIndicator();
    document.body.classList?.remove('loading');
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', stopLoadingIndicator);
    router.events.on('routeChangeError', stopLoadingIndicator);
    return () => {
      stopLoadingIndicator();
      router.events.off('routeChangeStart', startLoadingIndicator);
      router.events.off('routeChangeComplete', stopLoadingIndicator);
      router.events.off('routeChangeError', stopLoadingIndicator);
    };
  }, []);

  const getLayout = Component.getLayout || ((page) => page);
  const mainLayout =
    Component.getMainLayout || ((page) => <Layout>{page}</Layout>);

  return (
    <div className={''}>
      <div className="tooltip-container ">
        <Tooltip id="listTooltip" className="list-tooltip" clickable />
      </div>
      {renderClient ? (
        <Fragment>
          <Script id="hotjar-tracking" strategy="afterInteractive">
            {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${publicRuntimeConfig.HJ_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </Script>
        </Fragment>
      ) : null}
      {renderClient && (
        <Fragment>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GA_ID}`}
            strategy="afterInteractive"
          />
          {/* <Script id="twitterConv">
            {`
            <!-- Twitter conversion tracking base code -->
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','od6so');
<!-- End Twitter conversion tracking base code -->
            `}
          </Script> */}
          <Script id="apl">
            {`
function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script");
o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
o.onload=function(){window.trackingFunctions.onLoad({appId:"663224f3689f5a06ad2db096"})},
document.head.appendChild(o)}initApollo();
`}
          </Script>

          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${publicRuntimeConfig.GA_ID}');
        `}
          </Script>
        </Fragment>
      )}
      <PlausibleProvider
        trackLocalhost={false}
        trackOutboundLinks={true}
        domain="markets.sh"
        enabled={!publicRuntimeConfig.development}
      >
        <SocketProvider initialSocket={socket}>
          <NotificationProvider>
            <AppStateContextProvider>
              <ThemeProvider
                attribute="class"
                defaultTheme="system"
                forcedTheme={Component?.forcedTheme || undefined}
              >
                <MyUserContextProvider>
                  {renderClient && <PageViewTracker />}
                  {renderClient &&
                  localStorage.getItem('tracking-opt-out') === null &&
                  !isApp ? (
                    <CookieNotice />
                  ) : null}

                  {mainLayout(getLayout(<Component {...pageProps} />))}
                </MyUserContextProvider>
              </ThemeProvider>
            </AppStateContextProvider>
          </NotificationProvider>
        </SocketProvider>
      </PlausibleProvider>
    </div>
  );
}

export default MyApp;
