import { X } from 'lucide-react';
import Link from 'next/link';
import { useState } from 'react';

export default function CookieNotice() {
  const [show, setShow] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('tracking-opt-out') === null;
    }
    return false;
  });

  if (!show) {
    return null;
  }

  function giveConsent() {
    setShow(false);
    localStorage.setItem('tracking-opt-out', 'false');
  }

  return (
    <div className="fixed z-40 border-red w-full md:w-72 bottom-0 right-0s md:bottom-5 md:right-5   p-2 hidden md:block">
      <div className="border bg-white dark:bg-black/90 border-line p-5 rounded-xl text-xs shadow-lg ">
        <button
          className="absolute top-2 right-2 m-2 z-30"
          onClick={() => {
            localStorage.setItem('tracking-opt-out', 'false');
            setShow(false);
          }}
        >
          <X />
        </button>
        <p className="mb-5 text-xs opacity-90">
          This site uses cookies to measure and improve your experience. Visit{' '}
          <Link legacyBehavior href="/privacy">
            <a className="text-blue-600">Privacy Policy</a>
          </Link>{' '}
          for more information.
        </p>
        <div className="flex flex-row gap-3">
          <button
            className="text-xs text-blue-600  underline py-1 px-3 rounded-lg font-medium"
            onClick={() => {
              setShow(false);
              localStorage.setItem('tracking-opt-out', 'true');
            }}
          >
            Opt-out
          </button>
          <button
            className="text-xs border border-line py-3 px-8 rounded-lg bg-blue-500 font-medium text-white"
            onClick={() => {
              giveConsent();
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}
