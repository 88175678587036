import { RealtimeQuote } from '@/types/frontend/page';
import Link from 'next/link';
import cn from 'classnames';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useDebouncedCallback } from 'use-debounce';
import TickerLogo from '../Elements/TickerLogo';
import { useTheme } from 'next-themes';
import { topicScale } from '../utils/colors';
import { useUser } from '@/utils/useUser';
import { Search as SearchIcon } from 'lucide-react';
import { useNotification } from '@/hooks/useNotification';
import { supabase } from '@/utils/supabase-client';

const marketFilterOptions = [
  { value: 'All', label: 'All' },
  { value: 'STOCKS', label: 'Stocks' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'ECONOMIC_INDICATOR', label: 'Economic Indicator' },
  { value: 'COMMODITY', label: 'Commodity' },
  { value: 'CRYPTO', label: 'Crypto' },
  { value: 'OTC', label: 'Otc' },
  { value: 'FX', label: 'Fx' }
];

const isUrl = (input: string) => {
  const urlRegex =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  return urlRegex.test(input);
};

type SearchProps = {
  navbarMode?: boolean;
  loadFocussed?: boolean;
};

const Search = ({ navbarMode = true, loadFocussed = false }: SearchProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [resultCount, setResultCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const [input, setInput] = useState('');
  const [result, setResult] = useState<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [onboardMode, setOnboardMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const colors = useMemo(() => {
    return topicScale();
  }, []);

  const resetSearch = () => {
    disableSearch();
    setActiveIndex(-1);
    setResult([]);
    inputRef.current?.blur();
  };

  const indexIncrement = useRef(0);

  const doSearchImmediate = (queryString: string) => {
    const currentIdx = indexIncrement.current;
    const url = `/api/v2/search?q=${queryString}&limit=35`;
    setActiveIndex(-1);
    setLoading(true);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (currentIdx === indexIncrement.current) {
          setResult(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const doSearch = useDebouncedCallback((queryString: string) => {
    doSearchImmediate(queryString);
  }, 100);

  const { user } = useUser();
  const disableSearch = () => {
    setSearchActive(false);
    setInput('');
    setResult([]);
  };

  const updateSearchInput = (newInput: string) => {
    // Immediately update input for responsive UI
    setInput(newInput);

    // Update search active state
    if (newInput.length > 0) {
      setSearchActive(true);
    } else {
      setSearchActive(true); // Keep search active even with empty input
    }

    // Handle onboard mode
    if (user?.id && isUrl(newInput)) {
      setOnboardMode(true);
    } else {
      setOnboardMode(false);
    }

    // Increment search index and trigger debounced search
    indexIncrement.current = indexIncrement.current + 1;
    doSearch(newInput);
  };

  // Remove the separate useEffect since we're handling search in updateSearchInput
  useEffect(() => {
    // Only trigger search when market filters change and we have an input
    if (input) {
      doSearchImmediate(input);
    }
  }, [input]);

  const nextItem = () => {
    setActiveIndex((activeIndex + 1) % resultCount);
  };
  const prevItem = () => {
    setActiveIndex((activeIndex - 1 + resultCount) % resultCount);
  };

  const doOnboard = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('msh_company_requests')
      .insert({ query: input, compile_data: true });

    if (error) {
      setLoading(false);
      notification.showError({
        title: 'Error',
        message: `Could not create report. Please try again later`
      });
    } else {
      setLoading(false);
      notification.showNotification({
        title: 'Success',
        message: `Report Was successfully created.`
      });
      setSearchActive(false);
      setInput('');
    }
  };

  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    loadFocussed && focus();
  }, []);

  const { resolvedTheme: theme } = useTheme();

  const go = () => {
    if (!searchActive) {
      return;
    }
    if (activeIndex >= 0 && activeIndex < result.length) {
      const selectedResult = result?.symbols?.hits?.hits[activeIndex]?._source;
      if (selectedResult?.msh_id) {
        window.location.href = `/symbols/${selectedResult.msh_id}`;
      }
    }
  };
  const handleEscape = () => {
    resetSearch();
    if (input.length < 1) {
      disableSearch();
    }
  };

  const ctrl = useKeyPress('k', focus, true, true);
  useKeyPress('ArrowDown', nextItem, false);
  useKeyPress('ArrowUp', prevItem, false);
  useKeyPress('Enter', go, false);
  useKeyPress('Escape', handleEscape, false);
  const inputChattable = user?.id && input?.length > 8 && input?.includes(' ');

  return (
    <Fragment>
      {searchActive && (
        <div
          className={cn(' h-screen  top-0 left-0 z-50 transition-all	', {
            'bg-black bg-opacity-50 fixed w-screen': navbarMode,
            hidden: !navbarMode
          })}
          onClick={() => disableSearch()}
        ></div>
      )}
      <div
        className={cn(
          'relative  items-center text-gray-700  border-line h- z-50   lg:top-1.5',
          {
            '  md:w-1/3  mx-auto md:absolute md:left-1/2 lg:-translate-x-1/2 lg:-ml-10 -ml-5 ':
              navbarMode,
            'w-full mb-5 ': !navbarMode
          }
        )}
      >
        <input
          className={cn('input-default px-5 pr:0  w-full text-md ', {
            'shadow-lg': searchActive && navbarMode
          })}
          value={input}
          ref={inputRef}
          type="search"
          autoComplete="off"
          onFocus={() => {
            setSearchActive(true);
            doSearchImmediate(input); // Always do initial search, even with empty input
          }}
          name="search"
          placeholder="Search"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateSearchInput(e.target.value)
          }
        />
        <button
          type="submit"
          name="submit search"
          className={cn('absolute top-1/2 right-3 -translate-y-1/2 ', {
            'opacity-0': searchActive
          })}
        >
          <SearchIcon className="text-gray-400 w-4 h-4" />
        </button>
      </div>
      {searchActive && (
        <div className="relative z-50 h-full flex  ">
          <div
            className={cn('bg-white dark:bg-black/95    z-50  py-5 ', {
              'max-w-3xl  fixed w-screen max-h-screen h-screen-1/2 md:max-h-[80vh]  dark:backdrop-blur-xl overflow-y-auto lg:rounded-lg md:w-1/2  inset-center-x top-20':
                navbarMode,
              'border border-line': navbarMode,
              'w-full': !navbarMode
            })}
          >
            <div className="w-full border-b border-line pb-2">
              <div className="flex flex-row justify-between items-center px-5 w-full">
                <div className="flex flex-row items-center gap-x-3">
                  <span className="text-xs py-2">
                    Results ({result?.symbols?.hits?.total?.value || 0})
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div>
                {onboardMode && (
                  <div className="w-full hover:bg-blue-500 hover:text-white py-4 text-center">
                    <button
                      onMouseEnter={() => {
                        setActiveIndex(-1);
                      }}
                      onClick={() => {
                        doOnboard();
                      }}
                    >
                      <h2 className="font-bold">Onboard Company</h2>
                      <h3>
                        Create report for {input}. We will inform you once it is
                        ready
                      </h3>
                    </button>
                  </div>
                )}
                {result?.symbols?.hits?.hits.length > 0 ? (
                  <ul className={`mb-5`}>
                    {result?.symbols?.hits?.hits
                      .map((h) => h?._source)
                      .map((quote: RealtimeQuote, idx: number) => {
                        return (
                          <Link
                            key={`idx-${quote.msh_id}-${idx}`}
                            href={`/symbols/${quote.msh_id}`}
                            className="max-w-md"
                            onClick={() => disableSearch()}
                          >
                            <li
                              className={`px-2 ${
                                activeIndex == idx
                                  ? 'bg-blue-500 hover:bg-blue-600 text-white'
                                  : 'hover:bg-blue-500 '
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                              }}
                              onMouseEnter={() => {
                                setActiveIndex(idx);
                              }}
                            >
                              <div className="py-2 px-2 cursor-pointer flex flex-row ">
                                <div className="w-10 h-10 rounded mr-3 flex-shrink-0 ">
                                  <TickerLogo quote={quote} />
                                </div>
                                <div className={'leading-tighter'}>
                                  <h3 className="text-xs whitespace-nowrap flex flex-col font-medium ">
                                    {quote.msh_id}
                                  </h3>
                                  <span className=" font-medium">
                                    {quote.name}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </Link>
                        );
                      })}
                  </ul>
                ) : (
                  <div className="">
                    <p className="text-center opacity-80 text-sm m-5 py-10 border-line border-y">
                      No Results Found for Your Query
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Search;
