import { useUser } from '@/utils/useUser';
import { usePathname } from 'next/navigation';
import { navigationItems } from '@/components/data/navigation';
import { NavSection } from '@/components/Sidebar';

export function Navigation() {
  const { userDetails } = useUser();
  const pathname = usePathname();

  return (
    <div className="h-full w-full">
      {Object.keys(navigationItems).map((ni) =>
        (ni != 'dev' || userDetails?.show_dev_features) &&
        (ni != 'beta' || userDetails?.show_beta_features)
          ? NavSection({
              header: ni,
              items: navigationItems[ni],
              userDetails: userDetails,
              currentPath: pathname,
              sidebarActuallyOpen: true
            })
          : null
      )}
    </div>
  );
}
